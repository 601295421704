.btn {
    border: none;
    width: 100%;
    border-radius: 20px;
    min-height: 1.6rem;
    padding: 0.5rem;
    font-weight: 500;
    color: white;
    transition: 0.5s;
    border: 3px solid rgba(0, 0, 0, 0);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    gap: 0.4rem;

    @include breakpoint-up('medium') {
        padding: 0.8rem;
    }

    &:hover {
        cursor: pointer;
        color: $color-black;
        background: $color-white;
        border: 3px solid $color-primary;
    }
    
    svg, path {
        width: 2rem;
        height: 2rem;
        fill: white;
    }

    &--secondary {
        color: $color-black;
        background-color: $color-white;
        border: 3px solid $color-primary;

        &:hover {
            cursor: pointer;
            color: white;
            border: 3px solid $color-white;
            background-color: $color-primary;

            svg, path {
                fill: white
            }
        }

        svg, path {
            fill: $color-primary
        }
    }
    &--round {
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}
.btnlink {
    background: none;
    border: none;
    text-align: left;
    font-weight: 400;
    color: $color-black;
    transition: 0.5s;

    &:hover {
        cursor: pointer;
        color: $color-secondary;
    }
}
