.modal {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 1000;

    &--open {
        display: block;
    }

    &__content {
        position: absolute;
        width: 90vw;
        left: 5vw;
        max-height: 70%;
        top:50%;
        transform: translateY(-50%);
        transition: 0.5s;
        padding: 2.6rem 1.4rem;
        border-radius: 50px;
        background: linear-gradient(145deg, rgb(255 255 255), rgb(219 219 219));
        box-shadow:  33px 33px 66px rgba(0, 15, 37, 0.5),
             -33px -33px 66px rgba(0, 11, 11, 0.5);
        display: flex;
        flex-direction: column;
        align-items: center;

        @include breakpoint-up('medium') {
            width: 30vw;
            left: 35vw;
        }
    }
    &__header {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    &__ttl {
        color: $color-primary;
        font-size: 2.4rem;
        text-align: center;
        font-weight: 600;
        margin-bottom: 1.6rem;
    }
    &__close {
        position: absolute;
        top: 2rem;
        right: 2rem;
        background: none;
        border: none;

        svg, path {
            fill: $color-primary;
            width: 2rem;
            height: 2rem;
        }
    }
    &__select {
        border: none;
        color: $color-primary;
        min-height: 1.6rem;
        padding: 0.5rem;
        background: #EEF0F4;
        border-radius: 12px;
        box-shadow: inset 9.91px 9.91px 15px #D9DADE, inset -9.91px -9.91px 15px #FFFFFF;
    
        &:focus {
            outline: 1px solid $color-primary;
        }
    }
}