.bar {
    margin-top: 1.4rem;
    width: 100%;
    height: 10px;
    position: relative;
    border-radius: 15px;
    border: 1px solid #a0a0a0;

    &__percent {
        position: absolute;
        top: 0;
        top: -20px;
        transition: 0.5s;
    }
    &__fill {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        border-radius: 15px;
        transition: 0.5s;
    }
}