@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;400;500;700&display=swap');

html {
  font-size: 62.5%;
}

$breakpoints: (
  "mobile": (
    567px,
  ),
  "small": (
    767px,
  ),
  "medium": (
    992px,
  ),
  "large": (
    1200px,
  ),
  "1440": (
    1440px,
  ),
  "xlarge": (
    1700px,
  ),
) !default;

@mixin breakpoint-up($breakpoint) {
  @if map-has-key($breakpoints, $breakpoint) {
    @media (min-width: map-get($breakpoints, $breakpoint)) {
      @content;
    }
  }
}

$color-primary: #9900ff;
$color-primary-light: #c266ff;
$color-secondary: #147aff;
$color-grey: #f2f2f2;
$color-white: #ffffff;
$color-black: #262626;

*, h1, h2, h3, h4, h5, h6, p, a, strong, label, span, li, td, th {
  font-family: 'Poppins';
  font-weight: 400;
  font-size: 1.4rem;
  color: $color-black;
}

* {
  box-sizing: border-box;
}

body, #root {
  width: 100%;
  min-height: 100vh;
}

.background {
  background: linear-gradient(270deg, #ce85ff, #7193ff);
  background-size: 400% 400%;

  -webkit-animation: AnimationName 30s ease infinite;
  -moz-animation: AnimationName 30s ease infinite;
  animation: AnimationName 30s ease infinite;
}

@-webkit-keyframes AnimationName {
  0%{background-position:0% 50%}
  50%{background-position:100% 50%}
  100%{background-position:0% 50%}
}
@-moz-keyframes AnimationName {
  0%{background-position:0% 50%}
  50%{background-position:100% 50%}
  100%{background-position:0% 50%}
}
@keyframes AnimationName {
  0%{background-position:0% 50%}
  50%{background-position:100% 50%}
  100%{background-position:0% 50%}
}