.dashboard {
    width: 90%;
    margin: 8rem auto 15rem;

    &__ttl {
        color: $color-primary;
        font-size: 2.4rem;
        font-weight: 600;
        margin-bottom: 1rem;
    }
    &__subttl {
        color: $color-black;
        font-size: 2rem;
        font-weight: 500;
    }
    .alertbox {
        margin-top: 2rem;
        
        @include breakpoint-up('medium') {
            width: 50%;
        }
    }
}