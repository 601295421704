.prospections {
    width: 90%;
    margin: 8rem auto 15rem;

    &__header {
        width: 100%;
        margin-bottom: 1.4rem;
        padding-bottom: 1.4rem;
        border-bottom: 2px solid $color-grey;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        position: relative;

        @include breakpoint-up('medium') {
            flex-direction: row;
            justify-content: space-between;
            padding: 0 10% 1.4rem 10% ;
        }
    }
    &__ttl {
        font-size: 2.2rem;
        font-weight: 600;
        color: $color-primary;

        @include breakpoint-up('medium') {
            font-size: 2.6rem;
        }
    }
    &__menu {
        width: 100%;
        margin-top: 1.4rem;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;

        .btn {
            width: 50%;

            @include breakpoint-up('medium') {
                width: unset
            }
        }

        @include breakpoint-up('medium') {
            width: unset
        }
    }
    &__list {
        width: 100%;
        display: flex;
        flex-direction: column;

        @include breakpoint-up('medium') {
            flex-direction: row;
            align-items: center;
            flex-wrap: wrap;
            gap: 1rem;
        }
    }
    &__date {
        font-size: 1.4rem;
        margin-top: 1rem;
    }
    &__badge {
        margin-top: 1rem;
        grid-column: 1;
        grid-row: 1/3;
        border-radius: 3rem;
        padding: 1rem;
        font-size: 1rem;
        color: $color-white;
        align-self: center;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;

        @include breakpoint-up('medium') {
            font-size: 1.4rem;
        }
    }
    &__content {
        width: 100%;
        display: flex;
        flex-direction: column;

        @include breakpoint-up('medium') {
            width: 30%;
            margin: auto;
        }
    }
    &__infos {
        width: 100%;
        font-size: 1.4rem;
        margin-bottom: 1rem;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;

        span {
            width: 10rem;
        }
        strong {
            font-weight: 500;
        }
    }
    &__btnparams {
        position: absolute;
        right: 0;
        top: 0;
        background: none;
        fill: $color-primary;
        border: none;
        width: 10%;
        transition: 0.5s;

        &:hover {
            cursor: pointer;

            svg, path {
                fill: $color-black;
            }
        }

        svg, path {
            fill: $color-primary;
            width: 100%;
            height: 100%;
        }

        @include breakpoint-up('medium') {
            width: 3%;
            top: 0.8rem;
        }
    }
    &__params {
        position: absolute;
        width: 40vw;
        display: none;
        flex-direction: column;
        border-radius: 5px;
        top: 7rem;
        right: 5rem;

        &--open {
            display: flex;
        }

        @include breakpoint-up('medium') {
            width: 15vw;
            right: 15rem;
        }
    }
    &__btn {
        width: 100%;
        padding: 1rem;
        background: none;
        border: none;
        color: $color-white;
        transition: 0.5s;
        display: flex;
        flex-direction: row;
        align-items: center;

        &:hover {
            cursor: pointer;
            color: $color-secondary;

            svg, path {
                fill: $color-secondary;
            }
        }

        svg, path {
            fill: $color-white;
            margin-right: 1rem;
            width: 2rem;
            height: 2rem;
        }
    }
    &__back {
        position: absolute;
        left: 0;
        top: -0.5rem;
        background: none;
        fill: $color-primary;
        border: none;
        width: 10%;
        transition: 0.5s;

        &:hover {
            cursor: pointer;

            svg, path {
                fill: $color-black;
            }
        }

        svg, path {
            fill: $color-primary;
            width: 100%;
            height: 100%;
        }

        @include breakpoint-up('medium') {
            width: 3%;
        }
    }
    &__choice {
        width: 100%;
        display: grid;
        grid-template-columns: auto auto;
        grid-template-rows: auto auto auto;
        gap: 1rem;

        @include breakpoint-up('medium') {
            grid-template-columns: auto auto auto auto;
            grid-template-rows: auto auto;
        }

        .alertbox {
            grid-row: 1;
            grid-column: 1/3;
            width: unset;
            margin: unset;

            @include breakpoint-up('medium') {
                grid-column: 2/4;
            }
        }
    }
    .alertbox {
        margin: 2rem auto;

        @include breakpoint-up('medium') {
            width: 50%;
        }
    }
    &__subttl {
        font-size: 1.6rem;
        color: $color-black;
        grid-row: 1;
        grid-column: 1/3;

        @include breakpoint-up('medium') {
            grid-column: 1/5;
        }
    }
    &__choose:nth-child(2) {
        grid-column: 1;
        grid-row: 2;
    }
    &__choose:nth-child(3) {
        grid-column: 2;
        grid-row: 2;
    }
    &__choose:nth-child(4) {
        grid-column: 1;
        grid-row: 3;

        @include breakpoint-up('medium') {
            grid-column: 3;
            grid-row: 2;
        }
    }
    &__choose:nth-child(5) {
        grid-column: 2;
        grid-row: 3;

        @include breakpoint-up('medium') {
            grid-column: 4;
            grid-row: 2;
        }
    }
    &__choose {
        margin: auto;
        width: 15rem;
        height: 15rem;
        border: none;
        overflow: hidden;
        border-radius: 2rem;
        background: none;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        box-shadow: inset 9.91px 9.91px 15px #D9DADE, inset -9.91px -9.91px 15px #FFFFFF;

        &img {
            position: absolute;
            width: 100%;
            height: 100%;

            &:hover {
                cursor: pointer;
            }
        }

        .bar {
            align-self: flex-end;
            margin-bottom: 2rem;
        }
    }
    &__subsubttl {
        position: absolute;
        font-size: 2rem;
        color: $color-primary;
        font-weight: 500;
    }
    &__f2r {
        width: 100%;
        display: flex;
        flex-direction: column;

        @include breakpoint-up('medium') {
            width: 50%;
            margin: auto;
            align-items: center;
        }

        .alertbox {
            @include breakpoint-up('medium') {
                width: 100%;
            }
        }
    }
    &__f2rchoice {
        width: 50rem;
        height: 50rem;
        margin-top: 2rem;
        position: relative;
    }
    &__f2rimg {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
    }
    &__choicequarter {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        display: grid;
        grid-template-columns: auto auto auto auto auto;
        grid-template-rows: auto auto auto auto auto auto;
    }
    &__btnquarter {
        border: 2px solid $color-black;
        background: none;
        color: $color-primary;
        transition: 0.5s;
        text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.4);

        &:hover {
            cursor: pointer;
            color: $color-white;
            background: rgba(153, 0, 255, 0.5);
        }
    }
    &__table {
        margin: 2rem auto 0;
    }
    &__thead {
        text-align: center;
    }
    &__td, &__th {
        border: 1px solid $color-grey;
        padding: 0.6rem;
    }
    &__th {
        color: $color-primary;
    }
    &__td {
        &--attributed {
            background-color: orange;
        }
        &--completed {
            background-color: green;
        }
        &--not {
            background-color: red;
        }
    }
    &__select {
        border: none;
        color: $color-primary;
        min-height: 1.6rem;
        padding: 0.5rem;
        background: #EEF0F4;
        border-radius: 12px;
        box-shadow: inset 9.91px 9.91px 15px #D9DADE, inset -9.91px -9.91px 15px #FFFFFF;
    
        &:focus {
            outline: 1px solid $color-primary;
        }
    }
}