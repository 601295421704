.streets {
    width: 90%;
    margin: 8rem auto 15rem;

    &__header {
        width: 100%;
        margin-bottom: 1.4rem;
        padding-bottom: 1.4rem;
        border-bottom: 2px solid $color-grey;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        position: relative;
    }
    &__ttl {
        font-size: 2.2rem;
        font-weight: 600;
        color: $color-primary;
    }
    &__menu {
        width: 100%;
        margin-top: 1.4rem;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;

        .btn {
            width: 50%;
        }
    }

    &__table {
        width: 100%;
    }
    &__thead {
        text-align: center;
    }
    &__td {
        border: 1px solid $color-grey;
        padding: 0.6rem;
    }
    &__btns {
        
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-around;
    }
    &__btn {
        width: 100%;
        padding: 1rem;
        background: none;
        border: none;
        color: $color-primary;
        transition: 0.5s;
        display: flex;
        flex-direction: row;
        align-items: center;
        text-align: center;

        &:hover {
            cursor: pointer;
            color: $color-secondary;

            svg, path {
                fill: $color-secondary;
            }
        }

        svg, path {
            fill: $color-primary;
            width: 2rem;
            height: 2rem;
        }
    }
    &__btnparams {
        position: absolute;
        right: 0;
        top: 0;
        background: none;
        fill: $color-primary;
        border: none;
        width: 10%;
        transition: 0.5s;

        &:hover {
            cursor: pointer;

            svg, path {
                fill: $color-black;
            }
        }

        svg, path {
            fill: $color-primary;
            width: 100%;
            height: 100%;
        }
    }
    &__params {
        position: absolute;
        width: 40vw;
        display: none;
        flex-direction: column;
        border-radius: 5px;
        top: 7rem;
        right: 5rem;

        &--open {
            display: flex;
        }
    }
    &__btn {
        width: 100%;
        padding: 1rem;
        background: none;
        border: none;
        color: $color-white;
        transition: 0.5s;
        display: flex;
        flex-direction: row;
        align-items: center;

        &:hover {
            cursor: pointer;
            color: $color-secondary;

            svg, path {
                fill: $color-secondary;
            }
        }

        svg, path {
            fill: $color-white;
            margin-right: 1rem;
            width: 2rem;
            height: 2rem;
        }
    }

    .map {
        margin-bottom: 2rem;
    }
}