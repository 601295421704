.registrationDiv {
    width: 100vw;
    height: 100vh;
}

.registration {
    position: fixed;
    width: 90vw;
    max-height: 90vh;
    overflow-y: auto;
    left: 5vw;
    padding: 2.6rem 1.4rem;
    border-radius: 50px;
    z-index: 99;
    background: linear-gradient(145deg, rgb(255 255 255), rgb(219 219 219));
    box-shadow:  33px 33px 66px rgba(165, 180, 217, 0.5),
             -33px -33px 66px rgba(223, 244, 255, 0.5);
    transition: 0.5s;
    opacity: 1;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    flex-direction: column;

    @include breakpoint-up('medium') {
        width: 30vw;
        left: 35vw;
    }

    &--left {
        left: -100vw;
        opacity: 0;
    }
    &--leftdouble {
        left: -200vw;
        opacity: 0;
    }
    &--right {
        left: 100vw;
        opacity: 0;
    }
    &--rightdouble {
        left: 200vw;
        opacity: 0;
    }

    &__img {
        width: 40vw;
        margin: 0 auto 1rem;

        @include breakpoint-up('medium') {
            width: 7vw;
        }
    }
    &__ttl {
        font-weight: 600;
        font-size: 2.2rem;
        margin-bottom: 2.2rem;
        color: $color-primary;
    }
    &__help {
        font-style: italic;
        font-size: 1.2rem;
        margin-bottom: 1.2rem;
    }
}