.footer {
    position: absolute;
    bottom: -15%;
    left: 0;
    width: 100vw;
    padding: 2rem;
    background-color: $color-black;
    z-index: 99;

    @include breakpoint-up('medium') {
        padding: 1rem 15vw;
        position: fixed;
        bottom: 0;
    }

    &__list {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;

        @include breakpoint-up('medium') {
            flex-direction: row;
        }
    }
    &__item {
        width: 100%;
        margin: 0.8rem 0;
    }
    &__link {
        font-size: 1.2rem;
        color: $color-white;
        transition: 0.5s;

        &:hover {
            cursor: pointer;
            color: $color-primary;
        }
    }
}