.map {
    width: 400px;
    height: 400px;
    position: relative;
    margin: auto;

    &__background {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
    }
    &__streets {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
    }
    &__street {
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        position: absolute;
        fill: $color-primary;
    }
    &__path {
        transition: 0.5s;

        &--not {
            fill: red;
        }
        &--attributed {
            fill: orange;
        }
        &--completed {
            fill: green;
        }

        &:hover {
            cursor: pointer;
            fill: $color-primary;
        }
    }
}