.annexe {
    width: 90%;
    margin: 8rem auto;

    &__ttl {
        color: $color-primary;
        font-size: 2.4rem;
        font-weight: 600;
        margin-bottom: 1rem;
    }
    &__subttl {
        color: $color-black;
        font-size: 2rem;
        font-weight: 500;
    }
    &__list {
        width: 100%;
        margin-top: 1.8rem;
        display: flex;
        flex-direction: column;
    }
    &__item {
        width: 100%;
    }
    &__link {
        width: 100%;
        margin-bottom: 1.2rem;
        font-size: 1.4rem;
        display: flex;
        flex-direction: row;
        align-items: center;
    }
    svg, path {
        width: 2rem;
        height: 2rem;
        margin-right: 1rem;
        fill: $color-primary;
    }
    &__link, &__subttl, strong, &__subsubttl {
        color: $color-primary;
    }
    &__subttl, strong {
        font-weight: 500;
    }
    &__subttl, &__text {
        margin-top: 1.2rem;
    }
}