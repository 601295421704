.header {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;

    &__menubtn {
        position: absolute;
        top: 1rem;
        left: 1rem;
        width: 5rem;
        height: 5rem;
        border-radius: 50%;
        background: linear-gradient(145deg, rgb(153 0 255), rgb(118, 3, 194));
        box-shadow:  33px 33px 66px rgba(165, 180, 217, 0.5),
             -33px -33px 66px rgba(223, 244, 255, 0.5);
        border: 2px solid rgba(0, 0, 0, 0);
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        transition: 0.5s;

        svg, path {
            width: 70%;
            height: 70%;
            fill: white;
            transition: 0.5s;
        }

        &:hover {
            cursor: pointer;
            background: $color-white;
            border: 2px solid $color-primary;

            svg, path {
                fill: $color-primary;
            }
        }
    }
    &__menu {
        width: 0;
        height: 0;
        opacity: 0;
        position: fixed;
        top: -100vh;
        left: -100vw;
        z-index: -9;
        transition: 0.5s;
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        @include breakpoint-up('medium') {
            transition: 0.8s;
        }

        &--active {
            width: 100vw;
            height: 100vh;
            opacity: 1;
            z-index: 999;
            top: 0;
            left: 0;

            @include breakpoint-up('medium') {
                width: 15vw;
            }
        }
    }
    &__list {
        width: 100%;
        height: 80%;
        margin-top: 3rem;
        overflow-y: auto;
        display: flex;
        flex-direction: column;
        align-items: center;

        &--bottom {
            height: 20%;
            margin-top: 0;

            .header__link {
                font-size: 1.2rem;
            }
        }
    }
    &__item {
        margin: 1rem 0;

        &--active {
            .header__link, .header__link svg {
                color: $color-white;
                fill: $color-white
            }
        }

        &:hover {
            .header__link, .header__link svg {
                color: $color-white;
                fill: $color-white
            }
        }
    }
    &__link {
        text-transform: uppercase;
        color: $color-black;
        font-size: 2.2rem;
        font-weight: 500;
        display: flex;
        flex-direction: row;
        align-items: center;
        transition: 0.5s;
        background: none;
        border: none;
        text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.4);

        &--red {
            color: #ff5555;

            svg, path {
                fill: #ff5555;
            }
        }
        svg {
            margin-right: 1rem;
            fill: $color-black;
            width: 3rem;
            height: 3rem;
            text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.4);
        }

        @include breakpoint-up('medium') {
            font-size: 1.8rem;
        }
    }
    &__btnclose {
        align-self: flex-end;
        cursor: pointer;
        background: $color-white;
        border: 2px solid $color-primary;
        position: relative;
        top: 1rem;
        right: 1rem;
        width: 5rem;
        height: 5rem;
        border-radius: 50%;
        box-shadow:  33px 33px 66px rgba(165, 180, 217, 0.5),
             -33px -33px 66px rgba(223, 244, 255, 0.5);
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        transition: 0.5s;

        @include breakpoint-up('medium') {
            width: 4rem;
            height: 4rem;
        }

        svg, path {
            width: 60%;
            height: 60%;
            fill: $color-primary;
            transition: 0.5s;

            @include breakpoint-up('medium') {
                width: 50%;
                height: 50%;
            }
        }

        &:hover {
            cursor: pointer;
            background-color: $color-primary;
            border: 2px solid 2px solid $color-white;

            svg, path {
                fill: $color-white;
            }
        }
    }
}