.form {
    width: 100%;
    display: flex;
    flex-direction: column;

    &__formgrp {
        width: 100%;
        margin-bottom: 1.2rem;
        display: flex;
        flex-direction: column;

        &--notif {
            display: flex;
            flex-direction: row;
            align-items: center;
            margin: 0.3rem 0;

            strong {
                font-weight: 600;
                color: $color-primary;
            }
        }
    }
    &__checkbox {
        margin-right: 0.8rem;
    }
    &__label {
        font-weight: 500;
        margin-bottom: 0.5rem;
    }
    &__input, &__select {
        border: none;
        color: $color-primary;
        min-height: 1.6rem;
        padding: 0.5rem;
        background: #EEF0F4;
        border-radius: 12px;
        box-shadow: inset 9.91px 9.91px 15px #D9DADE, inset -9.91px -9.91px 15px #FFFFFF;
    
        &:focus {
            outline: 1px solid $color-primary;
        }
    }
    &__listBtn {
        margin-top: 1.2rem;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;

        .btn:nth-child(2) {
            margin-top: 1.4rem;
        }

        &--row {
            flex-direction: row;
            align-items: center;
            justify-content: space-around;

            .btn:nth-child(2) {
                margin-top: 0;
            }
        }

        .btn {
            width: 40% !important;
        }

        &--registration {
            .btn {
                width: 100% !important;
            }
        }
    }
    &__help {
        font-style: italic;
        font-size: 1.2rem;
        margin-bottom: 1.2rem;
    }
}