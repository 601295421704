.alertbox {
    position: relative;
    padding: 1rem;
    margin-bottom: 1rem;
    border-radius: 0.375rem;
    border: 1px solid white;
    border-left: 5px solid white;

    &--success {
        color: #0a3622;
        background-color: #d1e7dd;
        border-color: #a3cfbb;
    }
    &--warning, &--waiting {
        color: #58151c;
        background-color: #fff3cd;
        border-color: #ffe69c;
    }
    &--error {
        color: #664d03;
        background-color: #f8d7da;
        border-color: #f1aeb5;
    }
    &--info {
        color: #004085;
        background-color: #cce5ff;
        border-color: #b8daff;
    }

    &__ttl {
        font-size: 2rem;
    }
}