.events {
    width: 90%;
    margin: 8rem auto 15rem;

    &__header {
        width: 100%;
        margin-bottom: 1.4rem;
        padding-bottom: 1.4rem;
        border-bottom: 2px solid $color-grey;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        position: relative;

        @include breakpoint-up('medium') {
            flex-direction: row;
            justify-content: space-between;
            padding: 0 10% 1.4rem 10% ;
        }
    }
    &__ttl {
        font-size: 2.2rem;
        font-weight: 600;
        color: $color-primary;

        @include breakpoint-up('medium') {
            font-size: 2.6rem;
        }
    }
    &__menu {
        width: 100%;
        margin-top: 1.4rem;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;

        .btn {
            width: 50%;

            @include breakpoint-up('medium') {
                width: unset
            }
        }

        @include breakpoint-up('medium') {
            width: unset
        }
    }
    &__list {
        width: 100%;
        display: flex;
        flex-direction: column;

        @include breakpoint-up('medium') {
            flex-direction: row;
            align-items: center;
            flex-wrap: wrap;
            gap: 1rem;
        }
    }
    &__date {
        font-size: 1.4rem;
        margin-top: 1rem;
    }
    &__badge {
        margin-top: 1rem;
        grid-column: 1;
        grid-row: 1/3;
        border-radius: 3rem;
        padding: 1rem;
        font-size: 1rem;
        color: $color-white;
        align-self: center;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;

        @include breakpoint-up('medium') {
            font-size: 1.4rem;
        }
    }
    &__content {
        width: 100%;
        display: flex;
        flex-direction: column;

        @include breakpoint-up('medium') {
            width: 30%;
            margin: auto;
        }
    }
    &__infos {
        width: 100%;
        font-size: 1.4rem;
        margin-bottom: 1rem;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;

        span {
            width: 10rem;
        }
        strong {
            font-weight: 500;
        }
    }
    &__btnparams {
        position: absolute;
        right: 0;
        top: 0;
        background: none;
        fill: $color-primary;
        border: none;
        width: 10%;
        transition: 0.5s;

        &:hover {
            cursor: pointer;

            svg, path {
                fill: $color-black;
            }
        }

        svg, path {
            fill: $color-primary;
            width: 100%;
            height: 100%;
        }

        @include breakpoint-up('medium') {
            width: 3%;
            top: 0.8rem;
        }
    }
    &__params {
        position: absolute;
        width: 40vw;
        display: none;
        flex-direction: column;
        border-radius: 5px;
        top: 7rem;
        right: 5rem;

        &--open {
            display: flex;
        }

        @include breakpoint-up('medium') {
            width: 15vw;
            right: 15rem;
        }
    }
    &__btn {
        width: 100%;
        padding: 1rem;
        background: none;
        border: none;
        color: $color-white;
        transition: 0.5s;
        display: flex;
        flex-direction: row;
        align-items: center;

        &:hover {
            cursor: pointer;
            color: $color-secondary;

            svg, path {
                fill: $color-secondary;
            }
        }

        svg, path {
            fill: $color-white;
            margin-right: 1rem;
            width: 2rem;
            height: 2rem;
        }
    }
    &__back {
        position: absolute;
        left: 0;
        top: 0;
        background: none;
        fill: $color-primary;
        border: none;
        width: 10%;
        transition: 0.5s;

        &:hover {
            cursor: pointer;

            svg, path {
                fill: $color-black;
            }
        }

        svg, path {
            fill: $color-primary;
            width: 100%;
            height: 100%;
        }

        @include breakpoint-up('medium') {
            width: 3%;
        }
    }
}

.event {
    width: 100%;
    border-radius: 50px;
    padding: 1.8rem;
    margin-bottom: 1.4rem;
    background: #f5f4f4;
    box-shadow:  20px 20px 60px #bebebe,
                -20px -20px 60px #ffffff;
    border: 3px solid $color-primary;
    display: grid;
    grid-template-columns: auto auto;
    grid-template-rows: auto auto;
    position: relative;

    @include breakpoint-up('medium') {
        width: 30%;
    }

    &--passed {
        border: 3px solid $color-primary-light;
        background: #a0a0a0;

        .event__bottom {
            border-top: 1px solid $color-grey;
        }
    }
    &__left {
        width: 100%;
        display: grid;
        grid-template-columns: auto auto;
        grid-template-rows: auto auto;
        gap: 1rem;
        grid-row: 1;
        grid-column: 1;
    }
    &__right {
        grid-row: 1;
        grid-column: 2;
        width: 50%;
        justify-self: flex-end;

        @include breakpoint-up('medium') {
            width: 20%;
        }
    }
    &__bottom {
        grid-row: 2;
        grid-column: 1/3;
        width: 90%;
        margin-top: 1.6rem;
        padding-top: 1.6rem;
        justify-self: center;
        border-top: 1px solid #a0a0a0;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-around;
    }
    &__btnbottom {
        background: none;
        border: none;
        color: $color-primary;
        transition: 0.5s;

        svg, path {
            fill: $color-primary;
            width: 2rem;
            height: 2rem;
        }

        &:hover {
            cursor: pointer;
            color: $color-secondary;

            svg, path {
                fill: $color-secondary;
            }
        }
    }
    &__badge {
        grid-column: 1;
        grid-row: 1/3;
        border-radius: 3rem;
        padding: 1rem;
        font-size: 1rem;
        color: $color-white;
        transform: rotateZ(337deg);
        align-self: center;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
    }
    &__btnparams {
        background: none;
        fill: $color-primary;
        border: none;
        width: 100%;
        transition: 0.5s;

        &:hover {
            cursor: pointer;

            svg, path {
                fill: $color-black;
            }
        }

        svg, path {
            fill: $color-primary;
            width: 100%;
            height: 100%;
            
            @include breakpoint-up('medium') {
                width: 3rem;
                height: 3rem;
            }
        }

        @include breakpoint-up('medium') {
            margin-right: 10rem;
        }
    }
    &__ttl {
        grid-column: 2;
        grid-row: 1;
        font-size: 2rem;
        font-weight: 500;
        color: $color-primary;
    }
    &__date {
        grid-column: 2;
        grid-row: 2;
        font-size: 1rem;
        line-height: 1.4rem;
        font-style: italic;

        strong {
            font-weight: 600;
            font-size: 1rem;
            line-height: 1.4rem;
        }
    }
    &__params {
        position: absolute;
        width: 40vw;
        display: none;
        flex-direction: column;
        border-radius: 5px;
        top: 1rem;
        right: 5rem;

        &--open {
            display: flex;
        }

        @include breakpoint-up('medium') {
            width: 15vw;
        }
    }
    &__btn {
        width: 100%;
        padding: 1rem;
        background: none;
        border: none;
        color: $color-white;
        transition: 0.5s;
        display: flex;
        flex-direction: row;
        align-items: center;

        &:hover {
            cursor: pointer;
            color: $color-secondary;

            svg, path {
                fill: $color-secondary;
            }
        }

        svg, path {
            fill: $color-white;
            margin-right: 1rem;
            width: 2rem;
            height: 2rem;
        }
    }
}