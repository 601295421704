.prospection {
    width: 100%;
    border-radius: 50px;
    padding: 1.8rem;
    margin-bottom: 1.4rem;
    background: #f5f4f4;
    box-shadow:  20px 20px 60px #bebebe,
                -20px -20px 60px #ffffff;
    border: 3px solid $color-primary;
    display: grid;
    grid-template-columns: auto auto;
    grid-template-rows: auto auto;
    position: relative;

    @include breakpoint-up('medium') {
        width: 30%;
    }

    &--passed {
        border: 3px solid $color-primary-light;
        background: #a0a0a0;

        .event__bottom {
            border-top: 1px solid $color-grey;
        }
    }
    &__left {
        width: 90%;
        justify-self: center;
        display: flex;
        flex-direction: column;
        gap: 1rem;
        grid-row: 1;
        grid-column: 1/3;
    }
    &__right {
        grid-row: 1;
        grid-column: 2;
        width: 50%;
        justify-self: flex-end;

        @include breakpoint-up('medium') {
            width: 20%;
        }
    }
    &__bottom {
        grid-row: 2;
        grid-column: 1/3;
        width: 90%;
        margin-top: 1.6rem;
        padding-top: 1.6rem;
        justify-self: center;
        border-top: 1px solid #a0a0a0;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-around;
    }
    &__btnbottom {
        background: none;
        border: none;
        color: $color-primary;
        transition: 0.5s;

        svg, path {
            fill: $color-primary;
            width: 2rem;
            height: 2rem;

            @include breakpoint-up('medium') {
                width: 3rem;
                height: 3rem;
                margin-right: 1rem;
            }
        }

        &:hover {
            cursor: pointer;
            color: $color-secondary;

            svg, path {
                fill: $color-secondary;
            }
        }
    }
    &__ttl {
        font-size: 2rem;
        font-weight: 500;
        color: $color-primary;
    }
    &__date {
        font-size: 1rem;
        line-height: 1.4rem;
        font-style: italic;

        strong {
            font-weight: 600;
            font-size: 1rem;
            line-height: 1.4rem;
        }
    }
    &__params {
        position: absolute;
        width: 40vw;
        display: none;
        flex-direction: column;
        border-radius: 5px;
        top: 1rem;
        right: 5rem;

        &--open {
            display: flex;
        }

        @include breakpoint-up('medium') {
            width: 15vw;
        }
    }
    &__btn {
        width: 100%;
        padding: 1rem;
        background: none;
        border: none;
        color: $color-white;
        transition: 0.5s;
        display: flex;
        flex-direction: row;
        align-items: center;

        &:hover {
            cursor: pointer;
            color: $color-secondary;

            svg, path {
                fill: $color-secondary;
            }
        }

        svg, path {
            fill: $color-white;
            margin-right: 1rem;
            width: 2rem;
            height: 2rem;
        }
    }
}